<template>
  <div
    class="flex flex-col w-full max-w-screen text-a-neutral relative font-sans tracking-wide"
  >
    <MainNav />
    <div class="flex flex-col px-4 md:px-8 lg:px-16 pb-12">
      <slot></slot>
    </div>
    <Footer />
  </div>
</template>
<script
    setup
    lang="ts"
>
</script>